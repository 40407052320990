export function findBySliceType(collection, type) {
  if (!Array.isArray(collection)) {
    return {
      primary: null,
      items: [],
    }
  }

  const data = collection.find(({ slice_type: sliceType }) => sliceType === type)
  return {
    primary: data?.primary || null,
    items: data?.items || [],
  }
}
