import { cloudFlareWorkersApi } from 'apis'

export async function getPrismicData(query) {
  const { data } = await cloudFlareWorkersApi.get(`/prismic`, {
    params: query,
  })

  return data
}

export async function getPrismicPreviewUrl(query) {
  const { data } = await cloudFlareWorkersApi.get(`/prismic/preview`, {
    params: query,
  })

  return data
}
