import pick from 'lodash/pick'
import { getPrismicData } from 'api/prismic'
import { konstants } from '@vizeat/helpers'

const filterNulls = (object) => {
  return Object.keys(object).filter((key) => {
    const item = object[key]
    if (item === null) return false
    // 'repeatable' Prismic elements are an array of objects
    if (typeof item === 'object' && item.length > 0) {
      return filterNulls(item[0]).length > 0
    }
    return true
  })
}

export function formatPrismicData(raw, locale = 'en') {
  // fallback to English
  const enData = raw.find((result) => result.lang === 'en')
  const localeData = locale !== 'en' ? raw.find((result) => result.lang === locale.toLowerCase()) : undefined
  const localeKeys = localeData ? filterNulls(localeData.data) : []

  const data = {
    ...(enData && { ...enData.data }),
    ...(localeData && { ...pick(localeData.data, localeKeys) }),
  }

  if (!Array.isArray(data.body)) return data
  return data.body.concat(data.body1 || [])
}

async function fetchPrismicData({ locale, field, matches, previewData }) {
  const data = await getPrismicData({ field, matches, ref: previewData?.ref })
  return formatPrismicData(data, locale)
}

export async function fetchPrismicHomePage({ locale, previewData }) {
  const data = await fetchPrismicData({
    locale,
    previewData,
    field: 'document.type',
    matches: konstants.PRISMIC_TYPES.HOME,
  })

  return data
}

export async function fetchPrismicPressPage({ locale, previewData }) {
  const data = await fetchPrismicData({
    locale,
    previewData,
    field: 'document.type',
    matches: konstants.PRISMIC_TYPES.PRESS,
  })

  return data
}

export async function fetchPrismicBecomeHostPage({ locale, previewData, campaign }) {
  const data = await fetchPrismicData({
    locale,
    previewData,
    field: `my.${konstants.PRISMIC_TYPES.BECOME_HOST}.url`,
    matches: campaign ? `/${campaign}` : '/',
  })

  return data
}

export async function fetchPrismicGiftPage({ locale, previewData, campaign }) {
  const data = await fetchPrismicData({
    locale,
    previewData,
    field: `my.${konstants.PRISMIC_TYPES.GIFT}.url`,
    matches: campaign ? `/${campaign}` : '/',
  })

  return data
}

export async function fetchPrismicLandingPage({ locale, previewData, slug }) {
  const data = await fetchPrismicData({
    locale,
    previewData,
    field: `my.${konstants.PRISMIC_TYPES.LANDING}.url`,
    matches: `/${slug}`,
  })

  return data
}

export async function fetchPrismicPartnerPage({ locale, previewData }) {
  const data = await fetchPrismicData({
    locale,
    previewData,
    field: 'document.type',
    matches: konstants.PRISMIC_TYPES.PARTNER,
  })
  return data
}

export async function fetchPrismicPrivateEventPage({ locale, previewData, slug }) {
  const data = await fetchPrismicData({
    locale,
    previewData,
    field: `my.${konstants.PRISMIC_TYPES.PRIVATE_EVENTS}.url`,
    matches: slug ? `/${slug}` : '/',
  })

  return data
}
