import { useQuery } from '@tanstack/react-query'
import { getPrismicData } from 'api/prismic'
import { STALE_TIME } from 'helpers/query'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { formatPrismicData } from 'services/prismic'
import { useCallback } from 'react'
import { konstants } from '@vizeat/helpers'

export function usePrismicQuery({ field, matches }, { select: transformData, ...reactQueryOptions } = {}) {
  const { token: ref } = useRouter().query
  const { language } = useTranslation().i18n

  return useQuery({
    keepPreviousData: true,
    staleTime: STALE_TIME,
    queryKey: ['prismic', language, field, matches, ref],
    queryFn: () => getPrismicData({ field, matches, ref }),
    select: useCallback(
      (raw) => {
        const data = formatPrismicData(raw, language)
        return transformData ? transformData(data) : data
      },
      [language, transformData],
    ),
    ...reactQueryOptions,
  })
}

export function usePrismicHomePageQuery(reactSelectOptions) {
  return usePrismicQuery({ field: 'document.type', matches: konstants.PRISMIC_TYPES.HOME }, reactSelectOptions)
}
